<template lang="pug">
div
  data-list-modal(ref="data_list_modal")
  div.root
    div(v-show="input_1") input 1 : {{ input_1 }}
    b-btn(@click="show_data_list_modal('input_1')") Modal data list input 1
    div.pb-2
    div(v-show="input_2") input 2 : {{ input_2 }}
    b-btn(@click="show_data_list_modal('input_2')") Modal data list input 2
    div.pb-5
    b-btn(@click="submit_test()") Test POST Success
    div.pb-2
    b-btn(@click="submit_test('miss_field')") Test POST Error missig field
    div.pb-2
    b-btn(@click="submit_test('submit_error')") Test POST Error missing field
</template>

<script>
import 'vue-awesome/icons/plug'
import { mapGetters } from 'vuex'
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/hourglass-start'
import 'vue-awesome/icons/hourglass-end'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Data_list_modal from '@/components/Data_list_modal.vue'
// import { get_element_by_id } from '@/utils'
import CONF from '@/conf'

export default ({
  name: 'Test_widgets',
  components: {
    Icon,
    ClipLoader,
    'data-list-modal': Data_list_modal
  },
  data () {
    return {
      loading: true,
      data_list_modal: null,
      input_1: null,
      input_2: null
    }
  },

  computed: {
    ...mapGetters({
    })
  },

  watch: {
  },

  methods: {
    show_data_list_modal: function (identifier) {
      this.data_list_modal.show(identifier, true)
    },
    submit_test: function (submit_type) {
      let payload = (submit_type === 'miss_field') ? {nothing: 'in there'} : {error: false}
      if (submit_type !== 'miss_field') payload.error = (submit_type === 'submit_error')
      let request = this.$http.post(CONF.TEST_URL, payload, CONF.REQUEST_OPTIONS)
      request.then(
        response => {
          console.log(`Success: ${response.status} ${JSON.stringify(response.body)}`)
          this.$toaster['success'](response.body.text)
          this.hide()
        },
        // ERROR
        response => {
          console.log(`Error: ${response.status} ${JSON.stringify(response.body)}`)
          this.$toaster['error'](response.body.text)
          this.error = response.status === 0 ? 'Timeout reached' : response.body
        }
      )
    }
  },

  mounted () {
    this.data_list_modal = this.$refs['data_list_modal']
    bus.$on('socket/state', (unused_response) => {
      bus.$emit('route/ready', null)
    })
    bus.$on('data_selection/input_1', (response) => {
      console.log('Got data selection for input 1')
      this.input_1 = response
    })
    bus.$on('data_selection/input_2', (response) => {
      console.log('Got data selection for input 2')
      this.input_2 = response
    })
    this.$socket.connect()
  },

  beforeDestroy: function () {
    bus.$off('socket/state')
    bus.$off('data_selection/input_1')
    bus.$off('data_selection/input_2')
  }
})
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

.root {
  margin: 10px;
  padding: 10px;
}

.animation {
  display: block;
  overflow: hidden;
}

.green {
  background-color: $green;
}

.period_form {
  position: absolute;
}
// .days_label {
//   font-size: 130%;
// }
//
// .days_input {
//   border-bottom-right-radius: 0;
//   border-right: 0 solid;
//   border-top-right-radius: 0;
//   font-size: 150%;
//   max-width: 120px;
//   min-width: 80px;
// }

p,
.animate_test {
  @include zoomout-transition;
  background-color: $blue;
  color: $white;
  font-size: 200%;
  padding: 5px;
  &.zoom {
    @include zoomin-transition;
  }
}

.chart {
  background-color: $btn_bgcolor;
  border: 2px solid $white_shadow;
  border-radius: 9px;
  color: $text_color;
  .icons {
    cursor: pointer;
    float: right;
  }
}

.col {
  color: $black;
  text-align: center;
}

.entrylist {
  color: $white;
  font-size: 130%;
  padding-top: 10px;
}

// .data_pk,
.days {
  min-width: 70px;
}

.entry_submit {
  float: left;
}

.separator {
  color: $black;
  display: inline-block;
  font-size: 1.5rem;
  padding: 0 1rem;
}
</style>
